import * as React from "react";
import { Link } from "gatsby";

// React
import { useState } from "react";
import { AdvancedImage, responsive, lazyload } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

// SVG
import Logo from "../images/logo/logo.inline.svg";
import LogoLetters from "../images/logo/logo-letters.inline.svg";

// Heroicons
import {
  ArrowNarrowRightIcon,
  ArrowNarrowDownIcon,
  CheckIcon,
} from "@heroicons/react/outline";

// Shared Components
import TextModal from "../components/shared/modal/Modal";
import LoadingButton from "../components/shared/loading-button/LoadingButton";
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";
import Seo from "../components/seo";

// Services
import { contactSubmission } from "../services/contactAPI";

// Animation
import ModuleAnimation from "../images/animation/ModuleAnimation";

const mobileExpectations = [
  { id: 1, title: "SELF-DISCOVERY" },
  { id: 2, title: "EMPOWERMENT" },
  { id: 3, title: "CLARITY" },
  { id: 4, title: "ACCOUNTABILITY" },
  { id: 5, title: "CONFIDENCE" },
  { id: 6, title: "INSPIRATION" },
  { id: 7, title: "STRATEGIC GOAL SETTING" },
  { id: 8, title: "EMOTIONAL INTELLIGENCE" },
  { id: 9, title: "SOCIAL INTELLIGENCE" },
  { id: 10, title: "PURPOSE" },
  { id: 11, title: "FULFILLMENT" },
  { id: 12, title: "HAPPINESS" },
  { id: 13, title: "SIGNIFICANCE" },
  { id: 14, title: "SUCCESS" },
];

const expectations1 = [
  { id: 1, title: "SELF-DISCOVERY" },
  { id: 2, title: "EMOTIONAL INTELLIGENCE" },
  { id: 3, title: "GOAL EXECUTION" },
  { id: 4, title: "CLARITY" },
  { id: 5, title: "PURPOSE" },
  { id: 6, title: "FOCUS" },
  { id: 7, title: "HAPPINESS" },
];

const expectations2 = [
  { id: 1, title: "EMPOWERMENT" },
  { id: 2, title: "FULFILLMENT" },
  { id: 3, title: "SELF AWARENESS" },
  { id: 4, title: "CONFIDENCE" },
  { id: 5, title: "SELF-ESTEEM" },
  { id: 6, title: "SOCIAL INTELLIGENCE" },
  { id: 7, title: "SIGNIFICANCE" },
];

const expectations3 = [
  { id: 1, title: "INSPIRATION" },
  { id: 2, title: "ACCOUNTABILITY" },
  { id: 3, title: "STRATEGY" },
  { id: 4, title: "PLANNING" },
  { id: 5, title: "RESPONSIBILITY" },
  { id: 6, title: "WELLNESS" },
  { id: 7, title: "SUCCESS" },
];

export default function Academy() {
  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "kirkwork",
    },
  });
  // define our cloudinary images
  const Surfer = cld
    .image(
      "v1646063753/Aritae/Academy/academy-kid-in-barrel-on-wave_upwqrf.jpg"
    )
    .quality("auto");
  const Surfer2 = cld
    .image(
      "v1646063753/Aritae/Academy/academy-kid-in-barrel-on-wave_upwqrf.jpg"
    )
    .quality("auto");
  const Career = cld
    .image("v1646063385/Aritae/Academy/academy-people-on-computer_jwoeaw.jpg")
    .quality("auto");
  const College = cld
    .image(
      "v1646063331/Aritae/Academy/academy-person-going-to-university_ny5axl.jpg"
    )
    .quality("auto");
  const Football = cld
    .image("v1646063418/Aritae/Academy/academy-football-players_ynd6hf.jpg")
    .quality("auto");
  const Academics = cld
    .image("v1646063299/Aritae/Academy/academy-girl-studying_bxr2bo.jpg")
    .quality("auto");
  const FootballGame = cld
    .image("v1648056287/Aritae/Academy/academy-football-game_coqcau.jpg")
    .quality("auto");
  const TeamPhoto = cld
    .image("v1647522731/Aritae/Academy/academy-team_x72dhr.jpg")
    .quality("auto");
  const Musician = cld
    .image(
      "v1646063276/Aritae/Academy/academy-woman-playing-guitar-on-stage_ir4gqz.jpg"
    )
    .quality("auto");
  const Studying = cld
    .image("v1646063236/Aritae/Academy/academy-aol_hzcguk.jpg")
    .quality("auto");
  const Artist = cld
    .image("v1646063088/Aritae/Academy/academy-girl-dancing-ballet_zejs7e.jpg")
    .quality("auto");

  const [isOpen, setIsOpen] = useState(false);
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  //email sent from contact form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const email = event.target.email.value;

    let postBody = JSON.stringify({
      Email: email,
      PageName: "Academy",
    });

    contactSubmission(postBody).then(async (response) => {
      const data = await response;
      if (data.dbSuccess === true && data.endUserEmailSuccess === true) {
        // modal success
        setIsOpen(true);
        setIcon("success");
        setDescription(
          "Thanks for your interest in Aritae! Please check your inbox for updates."
        );
        setLoading(false);
        event.target.reset();
      } else {
        // modal error
        setIsOpen(true);
        setIcon("failure");
        setDescription("Something went wrong. Please try again.");
        setLoading(false);
        event.target.reset();
      }
    });
  };

  // Modal
  function toggleModalOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <div id="root">
      <Header />
      <Seo title="About" description="Learn about Aritae and its founder." />

      <div className="relative bg-gray-100">
        {/* Modal */}
        <TextModal
          isOpen={isOpen}
          close={toggleModalOpen}
          icon={icon}
          description={description}
        />
        <div className="mx-auto">
          {/* START HERO SECTION */}
          <div className="h-screen relative">
            <div className="absolute inset-0">
              <div className="absolute flex-1 top-0 inset-0">
                <video
                  autoPlay={true}
                  muted
                  loop
                  playsInline
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                >
                  <source
                    src="https://res.cloudinary.com/kirkwork/video/upload/v1652714345/Aritae/Shared/About-Header_a4qvpo.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="relative">
              <div className="flex flex-col h-screen items-center justify-center">
                <h1 className="text-center text-4xl font-extrabold sm:text-5xl lg:text-6xl">
                  <span className="block text-white text-2xl pb-6">
                    ACADEMY
                  </span>
                </h1>
                <div
                  className={"flex flex-col items-center justify-center my-12"}
                >
                  <Logo fill={"white"} className={"h-32 w-auto mx-auto pb-2"} />
                  <LogoLetters
                    fill={"white"}
                    className={"h-auto w-48 mx-auto"}
                  />
                </div>
                <p className={"text-white text-center font-bold text-4xl mt-4"}>
                  UNLEASH YOUR POTENTIAL
                </p>
                <div className="hidden lg:flex mt-10 max-w-sm mx-auto justify-center">
                  <ArrowNarrowDownIcon className={"h-7 text-white"} />
                </div>
              </div>
            </div>
          </div>
          {/* END HERO SECTION */}

          {/* START ACADEMY Section */}
          <div className="py-12 md:py-24 mx-auto px-4 md:px-16 max-w-7xl">
            <div>
              <h2
                className={
                  "text-slate-600 text-center text-4xl px-12 pb-8 pt-24"
                }
              >
                WHAT IS THE ARITAE SELF-LEADERSHIP <b>ACADEMY</b>?
              </h2>
              <p
                className={"text-center text-xl px-12 pb-24 md:px-18 lg:px-48"}
              >
                We believe that every young person has the potential to be an
                asset in this world and that the need to invest in this “human
                potential” is immeasurable. We take a proactive approach to
                personal growth by utilizing the ARITAE program and the ARITAE
                Coach to guide young people to find the clarity, focus, and
                power needed to “unleash” their potential, become self-leaders,
                and live lives filled with the level of love, happiness,
                significance, and success that they desire.
              </p>
            </div>
            <div className={"relative mx-12"}>
              <div
                className={
                  "relative mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 p-4 md:p-10 max-w-9xl"
                }
              >
                <div className={"relative"}>
                  <div className={"flex justify-center"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50.74"
                      viewBox="0 0 100 100.74"
                    >
                      <g id="layer1" transform="translate(-0.758 -280.285)">
                        <path
                          id="path8136"
                          d="M90.758,280.288a1.588,1.588,0,0,0-.459.082l-9.582,3.1a1.678,1.678,0,0,0-1.072,2.117l.514,1.6L24.277,305.24a1.678,1.678,0,0,0-1.078,2.117l.514,1.593-12.774,4.129-.514-1.593a1.483,1.483,0,0,0-.052-.164,1.678,1.678,0,0,0-2.055-.924l-6.39,2.065a1.678,1.678,0,0,0-1.085,2.124l6.19,19.151a1.678,1.678,0,0,0,2.124,1.085l6.387-2.068a1.678,1.678,0,0,0,1.072-2.117l-.514-1.589,12.774-4.129.518,1.6a1.678,1.678,0,0,0,2.117,1.078l15.278-4.942a11.015,11.015,0,0,0,7.436,5.879L34.931,378.689a1.678,1.678,0,1,0,3.133,1.2l9.267-24.09h7.626v23.483a1.678,1.678,0,1,0,3.356,0V355.8c2.5,0,5.055.006,7.54-.019L75.2,379.885a1.68,1.68,0,1,0,3.133-1.212l-19.42-50.109a10.875,10.875,0,0,0,8.533-12.594l19.944-6.439.514,1.59a1.678,1.678,0,0,0,2.11,1.091l9.579-3.1a1.678,1.678,0,0,0,1.085-2.12L92.42,281.453a1.678,1.678,0,0,0-1.661-1.167Zm-1.016,3.792,7.226,22.356L90.581,308.5l-7.229-22.356Zm-8.553,6.3,5.165,15.966L66.374,312.8a10.967,10.967,0,0,0-20.717,5.04,10.9,10.9,0,0,0,.131,1.606L32.066,323.88q-2.573-7.984-5.152-15.966ZM56.628,310.208a7.622,7.622,0,1,1-7.616,7.629A7.607,7.607,0,0,1,56.628,310.208Zm-31.882,1.933,3.1,9.582-12.774,4.129-3.1-9.579Zm31.882.58a5.109,5.109,0,1,0,5.1,5.116A5.136,5.136,0,0,0,56.628,312.722Zm-48.88,1.39c1.883,5.823,3.469,10.732,5.161,15.969l-3.2,1.032L4.552,315.143Zm48.88,1.966a1.753,1.753,0,1,1-1.747,1.76A1.73,1.73,0,0,1,56.628,316.077Zm-1.671,19.895v16.47H48.622Zm3.356.354,6.246,16.116H58.312Z"
                          transform="translate(0 0)"
                          fill="#434f5b"
                        />
                      </g>
                    </svg>
                  </div>
                  <div
                    className={"relative pt-2 flex flex-col justify-between"}
                  >
                    <div className="text-center">
                      <h4
                        className={
                          "text-mustard-light text-xl font-bold text-center pt-4"
                        }
                      >
                        DISCOVERY
                      </h4>
                      <p className={"text-md text-center"}>
                        Through a process of self-reflection, players will gain
                        clarity, focus, and personal power.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={"relative"}>
                  <div className={"flex justify-center"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 100.002 100"
                    >
                      <g id="Page-1" transform="translate(0.001)">
                        <g
                          id="_047---Mountain-Route"
                          data-name="047---Mountain-Route"
                          transform="translate(-0.001)"
                        >
                          <path
                            id="Shape"
                            d="M56.226,16.667A3.343,3.343,0,0,0,53.351,15h-.683V11.2a3.322,3.322,0,0,0,1.667-2.87V5a3.322,3.322,0,0,0-1.667-2.87V1.667a1.667,1.667,0,1,0-3.333,0h-7.5a2.5,2.5,0,0,0-1.767,4.268l.733.732-.733.732a2.5,2.5,0,0,0,1.767,4.268h7.5V15h-.683a3.35,3.35,0,0,0-2.883,1.678L43.8,20.142a4.987,4.987,0,0,0-4.713,8.333L1.439,95.012A3.333,3.333,0,0,0,4.322,100H97.679a3.333,3.333,0,0,0,2.883-4.987ZM43.842,5H51V8.333H43.834A2.5,2.5,0,0,0,43.842,5Zm9.492,13.32,12.788,22.6-2.365,3.045-3.133-3.917a2.968,2.968,0,0,0-2.48-1.142,3.073,3.073,0,0,0-2.417,1.28l-.76,1.067-7.98-13.778a4.868,4.868,0,0,0-.382-5.517l2.045-3.622Zm-2.08,28.145-4.645-7.438a3.072,3.072,0,0,0-5-.283L38.5,42.635,36.334,40.1l5.75-10.167a5.052,5.052,0,0,0,.583.063,4.953,4.953,0,0,0,1.742-.333L52.844,44.23ZM42.667,23.333A1.667,1.667,0,1,1,41,25,1.667,1.667,0,0,1,42.667,23.333ZM34.594,43.19l1.585,1.855a3,3,0,0,0,2.368,1.078,3.058,3.058,0,0,0,2.355-1.15l3.083-3.855,4.648,7.445A3.06,3.06,0,0,0,51.134,50h.1a3.067,3.067,0,0,0,2.5-1.287l.937-1.315L57.2,51.773a4.958,4.958,0,0,0-1.178,2.913L41.721,58.77a5,5,0,1,0-1.247,7.023L57.892,78.572c-.038.132-.092.255-.12.392L42.1,81.067a4.985,4.985,0,0,0-9.432,2.267,4.918,4.918,0,0,0,.5,2.142L21.986,96.657l-17.652,0ZM61,53.333A1.667,1.667,0,1,1,59.334,55,1.667,1.667,0,0,1,61,53.333ZM39.334,61.667A1.667,1.667,0,1,1,37.667,60,1.667,1.667,0,0,1,39.334,61.667ZM36,83.333A1.667,1.667,0,1,1,37.667,85,1.667,1.667,0,0,1,36,83.333ZM26.7,96.657l8.833-8.833a4.918,4.918,0,0,0,2.133.51,5,5,0,0,0,4.888-3.965l15.68-2.1a4.987,4.987,0,1,0,1.618-6.395L42.436,63.093a5.029,5.029,0,0,0,.2-1.113l14.3-4.085a5.013,5.013,0,1,0,3.147-7.8L56.8,44.425l1.438-2.02,3.117,3.9a3.027,3.027,0,0,0,2.392,1.167,3.075,3.075,0,0,0,2.43-1.183L67.9,44.072,97.679,96.667ZM61,80a1.667,1.667,0,1,1,1.667,1.667A1.667,1.667,0,0,1,61,80Z"
                            transform="translate(-0.999 0)"
                            fill="#434f5b"
                          />
                          <path
                            id="Shape-2"
                            data-name="Shape"
                            d="M58.168,65.205a1.664,1.664,0,1,0,2.88-1.667L48.382,41.819a1.667,1.667,0,1,0-2.877,1.68Z"
                            transform="translate(28.5 27.295)"
                            fill="#434f5b"
                          />
                          <circle
                            id="Oval"
                            cx="1.667"
                            cy="1.667"
                            r="1.667"
                            transform="translate(70.43 62.702)"
                            fill="#434f5b"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div
                    className={"relative pt-2 flex flex-col justify-between"}
                  >
                    <div>
                      <h4
                        className={
                          "text-mustard-light text-xl font-bold text-center pt-4"
                        }
                      >
                        STRATEGY
                      </h4>
                      <p className={"text-md text-center"}>
                        Players will create a final plan that will give them the
                        skills, tools, and confidence to unleash their
                        potential.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={"relative lg:block"}>
                  <div className={"flex justify-center"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 100 100"
                    >
                      <g id="finish-line" transform="translate(-1 -1)">
                        <path
                          id="Path_593"
                          data-name="Path 593"
                          d="M99.387,30.032A1.612,1.612,0,0,0,101,28.419V2.613A1.612,1.612,0,0,0,99.387,1H2.613A1.612,1.612,0,0,0,1,2.613V28.419a1.612,1.612,0,0,0,1.613,1.613H4.226v61.29H2.613A1.612,1.612,0,0,0,1,92.935v6.452A1.612,1.612,0,0,0,2.613,101h12.9a1.612,1.612,0,0,0,1.613-1.613V92.935a1.612,1.612,0,0,0-1.613-1.613H13.9V75.194H39.71V96.161a4.839,4.839,0,1,0,9.677,0v-12.9a1.613,1.613,0,1,1,3.226,0v12.9a4.839,4.839,0,1,0,9.677,0V75.194H88.1V91.323H86.484a1.612,1.612,0,0,0-1.613,1.613v6.452A1.612,1.612,0,0,0,86.484,101h12.9A1.612,1.612,0,0,0,101,99.387V92.935a1.612,1.612,0,0,0-1.613-1.613H97.774V30.032ZM4.226,4.226H97.774V26.806H4.226ZM88.1,65.516H62.29v-7.4L72.3,48.108a4.332,4.332,0,0,0,1.279-3.087v-.945a4.367,4.367,0,0,0-4.366-4.366h-.945a4.341,4.341,0,0,0-3.087,1.279L60.873,45.3A14.037,14.037,0,0,1,58.6,47.134a8.065,8.065,0,1,0-15.2,0A13.968,13.968,0,0,1,41.127,45.3l-4.31-4.308a4.341,4.341,0,0,0-3.087-1.279h-.945a4.367,4.367,0,0,0-4.366,4.366v.945A4.332,4.332,0,0,0,29.7,48.108L39.71,58.119v7.4H13.9V30.032H88.1Zm-45.634-9.2L31.979,45.827a1.131,1.131,0,0,1-.334-.806v-.945a1.14,1.14,0,0,1,1.14-1.14h.945a1.14,1.14,0,0,1,.806.334l4.31,4.308a17.184,17.184,0,0,0,24.306,0l4.31-4.308a1.14,1.14,0,0,1,.806-.334h.945a1.14,1.14,0,0,1,1.14,1.14v.945a1.131,1.131,0,0,1-.334.806L59.537,56.311a1.609,1.609,0,0,0-.473,1.14v8.065H42.935V57.452A1.609,1.609,0,0,0,42.463,56.311Zm3.7-11.763A4.839,4.839,0,1,1,51,49.387,4.845,4.845,0,0,1,46.161,44.548ZM13.9,97.774H4.226V94.548H13.9ZM7.452,91.323V30.032h3.226v61.29Zm51.613,4.839a1.613,1.613,0,1,1-3.226,0v-12.9a4.839,4.839,0,1,0-9.677,0v12.9a1.613,1.613,0,1,1-3.226,0V75.194H59.065ZM13.9,71.968V68.742H88.1v3.226ZM97.774,97.774H88.1V94.548h9.677Zm-3.226-6.452H91.323V30.032h3.226Z"
                          fill="#434f5b"
                        />
                        <path
                          id="Path_594"
                          data-name="Path 594"
                          d="M13,6.613V21.129h3.226V14.677h6.452V11.452H16.226V8.226h6.452V5H14.613A1.612,1.612,0,0,0,13,6.613Z"
                          transform="translate(7.355 2.452)"
                          fill="#434f5b"
                        />
                        <path
                          id="Path_595"
                          data-name="Path 595"
                          d="M21,5h3.226V21.129H21Z"
                          transform="translate(12.258 2.452)"
                          fill="#434f5b"
                        />
                        <path
                          id="Path_596"
                          data-name="Path 596"
                          d="M31.452,12.684l-3.4-6.792A1.614,1.614,0,0,0,25,6.613V21.129h3.226V13.445l3.4,6.792a1.614,1.614,0,0,0,3.056-.721V5H31.452Z"
                          transform="translate(14.71 2.452)"
                          fill="#434f5b"
                        />
                        <path
                          id="Path_597"
                          data-name="Path 597"
                          d="M33,5h3.226V21.129H33Z"
                          transform="translate(19.613 2.452)"
                          fill="#434f5b"
                        />
                        <path
                          id="Path_598"
                          data-name="Path 598"
                          d="M37,9.839a4.845,4.845,0,0,0,4.839,4.839,1.613,1.613,0,1,1,0,3.226H37v3.226h4.839a4.839,4.839,0,0,0,0-9.677,1.613,1.613,0,0,1,0-3.226h4.839V5H41.839A4.845,4.845,0,0,0,37,9.839Z"
                          transform="translate(22.065 2.452)"
                          fill="#434f5b"
                        />
                        <path
                          id="Path_599"
                          data-name="Path 599"
                          d="M51.452,11.452H48.226V5H45V21.129h3.226V14.677h3.226v6.452h3.226V5H51.452Z"
                          transform="translate(26.968 2.452)"
                          fill="#434f5b"
                        />
                      </g>
                    </svg>
                  </div>
                  <div
                    className={"relative pt-2 flex flex-col justify-between"}
                  >
                    <div>
                      <h4
                        className={
                          "text-mustard-light text-xl font-bold text-center pt-4"
                        }
                      >
                        EXECUTION
                      </h4>
                      <p className={"text-md text-center"}>
                        With the guidance from their ARITAE Coach, players will
                        be held accountable so they can successfully execute
                        their plans.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END Academy Section */}

          {/* START 'FOR' Section */}
          <div className="bg-mustard bg-opacity-10">
            <div className="py-24 lg:pb-24 mx-2 md:mx-auto max-w-7xl">
              <h2
                className={
                  "text-slate-600 text-center text-4xl px-6 md:px-12 pb-14"
                }
              >
                THE <b>ARITAE</b> SELF-LEADERSHIP ACADEMY <b>IS FOR</b>
              </h2>
              <div
                className={
                  "relative grid grid-cols-1 md:grid-cols-3 gap-3 content-evenly mx-12 md:mx-24 lg:mx-56"
                }
              >
                <div className={"relative"}>
                  <div
                    className={
                      "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1"
                    }
                  >
                    <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1">
                      <AdvancedImage
                        plugins={[responsive(200)]}
                        cldImg={Academics}
                        alt="girl with long hair studying a book at a cafe"
                        className="h-full w-full object-cover object-top"
                      />
                    </div>
                    <div
                      className={"absolute inset-0 opacity-50 bg-stone-500"}
                    />
                    <div>
                      <h1
                        className={
                          "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-extrabold text-3xl text-center"
                        }
                      >
                        <p className="font-extrabold text-xl">Students</p>
                      </h1>
                    </div>
                  </div>
                </div>

                <div className={"relative"}>
                  <div
                    className={
                      "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1"
                    }
                  >
                    <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1">
                      <AdvancedImage
                        plugins={[responsive(200)]}
                        cldImg={Football}
                        alt="group of football players huddling in uniform"
                        className="h-full w-full object-cover object-top"
                      />
                    </div>
                    <div
                      className={"absolute inset-0 opacity-50 bg-stone-500"}
                    />
                    <div>
                      <h1
                        className={
                          "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-extrabold text-3xl text-center"
                        }
                      >
                        <p className="font-extrabold text-xl">Athletes</p>
                      </h1>
                    </div>
                  </div>
                </div>

                <div className={"relative"}>
                  <div
                    className={
                      "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1"
                    }
                  >
                    <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1">
                      <AdvancedImage
                        plugins={[responsive(200)]}
                        cldImg={Artist}
                        alt="woman dancing ballet outdoors"
                        className="h-full w-full object-cover object-top"
                      />
                    </div>
                    <div
                      className={"absolute inset-0 opacity-50 bg-stone-500"}
                    />
                    <div>
                      <h1
                        className={
                          "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-extrabold text-3xl text-center"
                        }
                      >
                        <p className="font-extrabold text-xl">Artists</p>
                      </h1>
                    </div>
                  </div>
                </div>

                <div className={"relative"}>
                  <div
                    className={
                      "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1"
                    }
                  >
                    <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1">
                      <AdvancedImage
                        plugins={[responsive(200)]}
                        cldImg={Musician}
                        alt="woman sitting and playing guitar on stage during a concert"
                        className="h-full w-full object-cover object-top"
                      />
                    </div>
                    <div
                      className={"absolute inset-0 opacity-50 bg-stone-500"}
                    />
                    <div>
                      <h1
                        className={
                          "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-extrabold text-3xl text-center"
                        }
                      >
                        <p className="font-extrabold text-xl">Musicians</p>
                      </h1>
                    </div>
                  </div>
                </div>

                <div className={"relative"}>
                  <div
                    className={
                      "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1"
                    }
                  >
                    <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1">
                      <AdvancedImage
                        plugins={[responsive(200)]}
                        cldImg={College}
                        alt="person walking with bookbag outside of college building on campus"
                        className="h-full w-full object-cover object-bottom"
                      />
                    </div>
                    <div
                      className={"absolute inset-0 opacity-50 bg-stone-500"}
                    />
                    <div>
                      <h1
                        className={
                          "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-extrabold text-3xl text-center"
                        }
                      >
                        <p className="font-extrabold text-xl">College Prep</p>
                      </h1>
                    </div>
                  </div>
                </div>

                <div className={"relative"}>
                  <div
                    className={
                      "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1"
                    }
                  >
                    <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1">
                      <AdvancedImage
                        plugins={[responsive(200)]}
                        cldImg={Career}
                        alt="four people working at a table on laptops"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <div
                      className={"absolute inset-0 opacity-50 bg-stone-500"}
                    />
                    <div>
                      <h1
                        className={
                          "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-extrabold text-3xl text-center"
                        }
                      >
                        <p className="font-extrabold text-xl">
                          Career Planning
                        </p>
                      </h1>
                    </div>
                  </div>
                </div>

                <div className="w-full max-w-7xl max-h-56 relative hidden md:block bg-slate-600 col-span-3 overflow-hidden">
                  <AdvancedImage
                    plugins={[responsive(200)]}
                    cldImg={Surfer}
                    alt="man surfing inside crest of wave"
                    className="opacity-70 w-full h-full object-cover object-center"
                  />
                  <div className={"absolute inset-0 opacity-50 bg-stone-500"} />

                  <div
                    className={
                      "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-extrabold text-2xl text-center"
                    }
                  >
                    Anyone who desires love, happiness, significance, and
                    success.
                  </div>
                </div>

                <div className={"relative md:hidden"}>
                  <div
                    className={
                      "aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1"
                    }
                  >
                    <div className="aspect-w-2 aspect-h-1 md:aspect-w-1 md:aspect-h-1">
                      <AdvancedImage
                        plugins={[responsive(200)]}
                        cldImg={Surfer2}
                        alt="man surfing inside of wave"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <div
                      className={"absolute inset-0 opacity-50 bg-stone-500"}
                    />
                    <div>
                      <h1
                        className={
                          "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-extrabold text-3xl text-center"
                        }
                      >
                        <p className="font-extrabold text-xl">
                          Anyone who desires love, happiness, significance, and
                          success.
                        </p>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END 'FOR' Section */}

          {/* START Academy Areas of Life Section */}
          <div className="pb-24 lg:mb-24 bg-mustard bg-opacity-10">
            <div className={"hidden lg:flex xl:hidden justify-end"}>
              <div
                className={
                  "relative max-w-6xl min-w-6xl bg-slate-600 h-[30rem] w-9/12"
                }
              >
                <AdvancedImage
                  plugins={[responsive(200)]}
                  cldImg={Studying}
                  alt="young people studying together at a table outdoors"
                  className={
                    "hidden lg:block absolute h-80 w-auto -left-48 bottom-20"
                  }
                />
                <div className="flex">
                  <p
                    className={
                      "text-white leading-relaxed text-left lg:mt-20 px-16 py-16 pl-52 xl:pl-80 font-bold text-2xl"
                    }
                  >
                    At the ARITAE Self-Leadership Academy you will discover what
                    it means to find success at{" "}
                    <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                      home
                    </span>
                    , at{" "}
                    <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                      school
                    </span>
                    , through your{" "}
                    <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                      extracurriculars
                    </span>{" "}
                    and with your{" "}
                    <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                      outside interests.
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* XL Screens */}
            <div>
              <div className="hidden xl:flex py-12 justify-center">
                <div className="bg-slate-600 mx-8 flex max-w-5xl shadow">
                  <div className="w-1/2">
                    <div className="h-64 bg-cover lg:rounded-lg lg:h-full">
                      <AdvancedImage
                        plugins={[responsive(200)]}
                        cldImg={Studying}
                        alt="young people studying together at a table outdoors"
                      />
                    </div>
                  </div>
                  <div className="py-6 px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
                    <p className="mt-4 p-8 text-white leading-relaxed font-bold py-20 text-2xl">
                      At the ARITAE Self-Leadership Academy you will discover
                      what it means to Unleash Your Potential at{" "}
                      <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                        home
                      </span>
                      , at{" "}
                      <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                        school
                      </span>
                      , in{" "}
                      <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                        sports
                      </span>
                      , in your{" "}
                      <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                        extracurricular activities
                      </span>{" "}
                      as well as your{" "}
                      <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                        outside interests.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Mobile */}
            <div className={"flex lg:hidden justify-center align-middle"}>
              <div className={"relative bg-slate-600 mx-8 p-8"}>
                <div className="flex">
                  <div className="py-6 px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
                    <p className="mt-4 p-8 text-white leading-relaxed font-bold py-20 text-2xl">
                      At the ARITAE Self-Leadership Academy you will discover
                      what it means to Unleash Your Potential at{" "}
                      <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                        home
                      </span>
                      , at{" "}
                      <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                        school
                      </span>
                      , in{" "}
                      <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                        sports
                      </span>
                      , in your{" "}
                      <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                        extracurricular activities
                      </span>{" "}
                      as well as your{" "}
                      <span className="underline decoration-solid decoration-mustard decoration-4 underline-offset-4">
                        outside interests.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END Academy Areas of Life Section */}

          {/* START 'What You Can Expect' Section */}
          <div className="relative mx-auto max-w-5xl bg-gray ">
            <div className="relative flex mx-auto justify-center">
              <div className="relative flex-1 max-w-5xl mx-0 lg:mx-16">
                <div className="relative flex-1">
                  <AdvancedImage
                    plugins={[responsive({ steps: 200 })]}
                    cldImg={TeamPhoto}
                    alt="hockey team kneeling on ice in rink while coach teaches from a whiteboard"
                    className="h-full w-full object-cover"
                  />
                </div>
                <div
                  className={"absolute flex inset-0 bg-overlay opacity-50"}
                />
                <Link to="/academy/coaches">
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-xl md:text-4xl text-center font-extrabold">
                    <h1>
                      WHAT YOU CAN EXPECT TO RECIEVE AND ACHIEVE AT THE ARITAE
                      SELF-LEADERSHIP ACADEMY
                    </h1>
                  </div>
                </Link>
              </div>
            </div>

            <div
              className={
                "hidden md:grid md:text-sm justify-items-center mt-12 max-w-7xl mx-auto"
              }
            >
              <div
                className={
                  "grid grid-cols-3 gap-3 text-white mx-8 lg:mx-0  text-md md:text-xs lg:text-lg font-bold mb-24"
                }
              >
                <div className="shadow p-2 overflow-hidden rounded-sm bg-slate-600">
                  <ul className="">
                    {expectations1.map((item) => (
                      <li key={item.id} className="px-4 py-5">
                        <CheckIcon className={"text-mustard h-6 pr-2 inline"} />

                        {item.title}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="shadow p-2 overflow-hidden rounded-sm bg-slate-600">
                  <ul>
                    {expectations2.map((item) => (
                      <li key={item.id} className="px-4 py-5">
                        <CheckIcon className={"text-mustard h-6 pr-2 inline"} />
                        {item.title}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="shadow p-2 overflow-hidden rounded-sm bg-slate-600">
                  <ul>
                    {expectations3.map((item) => (
                      <li key={item.id} className="px-4 py-5">
                        <CheckIcon className={"text-mustard h-6 pr-2 inline"} />
                        {item.title}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* END 'What You Can Expect' Section */}

          {/* START MOBILE 'What You Can Expect' Section */}

          <div className={"grid md:hidden justify-items-center pt-12"}>
            <div
              className={
                "grid grid-cols-1 mx-12 text-white text-md font-bold mb-14"
              }
            >
              <div className="shadow p-2 overflow-hidden rounded-sm mb-24 bg-slate-600">
                <ul>
                  {mobileExpectations.map((item) => (
                    <li key={item.id} className="px-6 py-5">
                      <CheckIcon className={"text-mustard h-6 pr-2 inline"} />

                      {item.title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* END MOBILE 'What You Can Expect' Section */}

          {/* START Animation */}
          <div className="bg-mustard bg-opacity-10">
            <div className="mx-auto py-24 px-4 md:px-16 max-w-7xl ">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-24">
                <div className="flex flex-col justify-center">
                  <h2
                    className={
                      "text-slate-600 text-left text-2xl font-bold uppercase"
                    }
                  >
                    THE ARITAE SELF-LEADERSHIP ACADEMY UTILITIZES THE
                    <span className="underline decoration-mustard decoration-4 underline-offset-4">
                      {" "}
                      5 module{" "}
                    </span>
                    ARITAE SELF-LEADERSHIP PROGRAM AND THE ARITAE COACH TO GUIDE
                    AND INSPIRE EACH PLAYER TOWARD A LIFE FILLED WITH LOVE,
                    HAPPINESS, SIGNIFICANCE AND SUCCESS.
                  </h2>
                  <div className="flex flex-col items-start pt-5 space-y-3 max-w-md">
                    <div className="flex flex-row space-x-5">
                      <p className="font-bold text-2xl text-navy">01</p>
                      <p className="font-serif">
                        <strong>Discovery.</strong> Deep thought and
                        self-reflection about life are the foundation for all to
                        come. The Player will be guided toward a deeper sense of
                        self-awareness that helps them get in touch with what is
                        real in their heart.
                      </p>
                    </div>
                    <div className="flex flex-row space-x-5">
                      <p className="font-bold text-2xl text-mustard">02</p>
                      <p className="font-serif">
                        <strong>Focus.</strong> The Player will use what they
                        discovered to formulate their vision and purpose of{" "}
                        <strong>who</strong> they are today,{" "}
                        <strong>what</strong> they want in the future, and a
                        better understanding of their <strong>why</strong>.
                      </p>
                    </div>
                    <div className="flex flex-row space-x-5">
                      <p className="font-bold text-2xl text-maroon">03</p>
                      <p className="font-serif">
                        <strong>Action.</strong> The Player will formalize their
                        set of goals that are in alignment with their stated
                        purpose and vision.
                      </p>
                    </div>
                    <div className="flex flex-row space-x-5">
                      <p className="font-bold text-2xl text-denim">04</p>
                      <p className="font-serif">
                        <strong>Strategy.</strong> The Player will design{" "}
                        <strong>how</strong> they will accomplish each of their
                        stated goals with ARITAE Microplans&trade;.
                      </p>
                    </div>
                    <div className="flex flex-row space-x-5">
                      <p className="font-bold text-2xl text-forest">05</p>
                      <p className="font-serif">
                        <strong>Personal Plan.</strong> The Player will organize
                        the information they discovered and work with their
                        coach to create their Personal Plan to accomplish their
                        goals and find love, happiness, significance and
                        success.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center order-first lg:order-last">
                  <div className="max-w-xl">
                    <ModuleAnimation />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END Animation */}

          {/* START Become a Section */}
          <div className="max-w-7xl mx-auto pt-24 pb-12 px-16">
            <div className="grid grid-rows-3 text-4xl divide-y divide-gray-300">
              <Link to={"/academy/players"}>
                <div
                  className={"flex flex-row py-8 justify-between hover:mr-2"}
                >
                  <p className={"text-mustard"}>
                    BECOME A <b>PLAYER</b>
                  </p>
                  <ArrowNarrowRightIcon className={"h-8 inline text-mustard"} />
                </div>
              </Link>

              <Link to={"/academy/coaches"}>
                <div
                  className={"flex flex-row py-8 justify-between hover:mr-2"}
                >
                  <p className={"text-navy"}>
                    BECOME A <b>COACH</b>
                  </p>
                  <ArrowNarrowRightIcon className={"h-8 inline text-navy"} />
                </div>
              </Link>

              <Link to={"/academy/parents"}>
                <div
                  className={"flex flex-row py-8 justify-between hover:mr-2"}
                >
                  <p className={"text-forest"}>
                    ARE YOU A <b>PARENT</b>?
                  </p>
                  <ArrowNarrowRightIcon className={"h-8 inline text-forest"} />
                </div>
              </Link>
            </div>
          </div>
          {/* END Become a Section*/}

          {/* START Sign Up Form */}
          <div className={"flex h-screen justify-center items-center"}>
            <div className="absolute z-10 flex h-screen overflow-hidden">
              <div className={"absolute inset-0 opacity-75 bg-stone-500"}></div>
              <AdvancedImage
                plugins={[lazyload()]}
                cldImg={FootballGame}
                alt="woman training for boxing with instructor inside gym"
                className={"flex min-h-screen object-cover m-auto opacity-40"}
              />
            </div>
            <div className="relative z-20 mx-auto max-w-3xl text-center md:px-5">
              <div className="py-10">
                <h4 className="text-4xl font-extrabold text-white">
                  ARE YOU READY TO UNLEASH YOUR POTENTIAL?
                </h4>
              </div>

              <form
                className="space-y-6 row-auto py-5 p-4  max-w-3xl xs:m-12 md:m-0"
                action="#"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div className="mx-auto">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-between">
                    <div className="mt-1 md:col-span-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="Email"
                        required
                        className="text-center md:text-left w-full px-5 py-3 text-gray-500 border-transparent placeholder-gray-500 bg-gray-100 font-sm md:font-md"
                      />
                    </div>

                    <div className="mt-1">
                      {loading ? (
                        <LoadingButton type={"email"} />
                      ) : (
                        <button
                          type="submit"
                          className="w-full flex justify-center px-5 py-3 border border-transparent rounded shadow-sm text-sm text-white font-bold bg-black hover:bg-gray-800"
                        >
                          SIGN UP
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* END Sign Up Form */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
